const endpoints = {
  auth: {
    login: "/auth/login",
    resetPassword: "/auth/reset-password",
    updatePassword: "/auth/update-password",
    logout: "/auth/logout",
    refreshToken: "/auth/refreshToken",
  },
  me: "/user/me",
  logoForOrganization: "file/upload/logo",
  getLogo: "/file/organization/logo",
  clinicians: "/clinicians",
  sendFax: "/anatomical-orders/send-fax",
  sendGravitee: "/anatomical-orders/send-gravitee",
  upload: "/file/upload",
  fileUrl: "/file/url",
  patientList: "/patients",
  patient: "/patient",
  testReportList: "/test-reports",
  testReport: "/test-report",
  testsForPatientsList: "/test-reports/patients",
  anatomicalTestForPatientsList: "/anatomical/test-reports/patients",
  singleAnatomicalOrder: "/anatomical/test-reports",
  anatomicalStatus: "/anatomical/status",
  myReports: "/patient/my-reports",
  bulkReports: "/reports",
  testForPatient: "/test-reports/patient",
  insuranceList: "/insurances",
  insurance: "/insurance",
  masterPanelsRead: "/master-panels",
  cptCodeRead: "/cpt-codes",
  anatomicalOrder: "/anatomical-orders",
  clinicalPanel: "/clinical-panels",
  locations: "/locations",
  locationsInOrgs: "locations-in-orgs",
  diagnosisLibraryRead: "diagnosis-library",
  commentLibraryRead: "/comments-library",
  comboPanel: "/combo-panel",
  masterTestRead: "/tests",
  admin: {
    comboPanel: "/admin/combo-panel",
    users: "/admin/users",
    newUser: "/auth/register",
    insuranceCompanies: "/admin/insurance-companies",
    commentLibraryWrite: "/admin/comments-library",
    diagnosisLibraryWrite: "/admin/diagnosis-library",
    connectMasterTestToMasterPanel: "/admin/panel/add-master-tests",
    dataSetup: {
      insurancePlanList: "/admin/insurance-plans",
      insurancePlan: "/admin/insurance-plan",
      insuranceTypeList: "/admin/insurance-types",
      insuranceType: "/admin/insurance-type",
      sampleTypeList: "/admin/sample-types",
      sampleType: "/admin/sample-type",
      insuranceGroupList: "/admin/insurance-groups",
      insuranceGroup: "/admin/insurance-group",
      insuranceRuleTypeList: "/admin/insurance-rule-types",
      insuranceRuleType: "/admin/insurance-rule-type",
      testMethodTypeList: "/admin/test-method-types",
      testMethodType: "/admin/test-method-type",
    },
    labSetup: {
      organization: "/admin/organization",
      orgStatus: "/admin/organization/active",
      myOrganization: "/admin/organization/my",
      organizationList: "/admin/organizations",
      location: "/admin/location",
      locationList: "/admin/locations",
      providerList: "/providers",
      adminProviderList: "/admin/providers",
      provider: "/admin/provider",
    },
    testSetup: {
      testMethodList: "/admin/test-methods",
      testMethod: "/admin/test-method",
      testList: "/admin/tests",
      test: "/admin/test",
      panelList: "/admin/panels",
      panel: "/admin/panel",
      orderSetList: "/admin/order-sets",
      orderSet: "/admin/order-set",
      anatomicalPanelList: "/admin/anatomical-panels",
      anatomicalPanel: "/admin/anatomical-panel",
    },
    librarySetup: {
      cptCodeList: "/admin/cpt-codes",
      singleCptCode: "/admin/cpt-code",
      icd10Codes: "/admin/icd10-codes",
      diagnosisCodeList: "/admin/diagnosis-codes",
      singleDiagnosisCode: "/admin/diagnosis-code",
      medicationList: "/admin/medications",
      singleMedication: "/admin/medication",
    },
    integrationPartner: {
      createVendor: "/admin/vendor",
      updateVendor: "/admin/vendor",
      vendorList: "/admin/vendor",
      deleteVendor: "/admin/vendor",
      connectionCreate: "/admin/vendor/connection",
      getSignleConnection: "/admin/vendor/connection",
    },
  },
};

export default endpoints;
